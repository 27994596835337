// Constants
import { URL_APP_MENU, ADDONS, THEME_SECONDARY_COLOR } from '@/constants'
// Components
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import ColorSelector from '@/components/ui/ColorSelector'
import FormButtons from '@/components/ui/FormButtons'
import GoogleFontsSelector from '@/components/ui/GoogleFontsSelector'
import PremiumMenuPreview from '@/addons/premiumMenu/components/elements/PremiumMenuPreview'
import PreviewImageUploader from '@/components/ui/PreviewImageUploader'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Custom validations
import { isOnlyNumbersLettersAndDash } from '@/utils'
// Services
import { isUrlAvailable } from '@/services/place'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'ConfigWeb',
  components: {
    AddonsNeedUpgradeContent,
    CardContainer,
    CardContainerElement,
    ColorSelector,
    FormButtons,
    GoogleFontsSelector,
    PremiumMenuPreview,
    PreviewImageUploader,
    VuetifyToolBar
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        color: null,
        cover: null,
        url: null
      },
      formFieldsValidations: {
        url: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 4 caracteres',
          urlAvailable: 'El enlace indicado ya existe, debes generar otro distinto',
          urlFormat:
            'Solo se permiten números y letras (sin caracteres latinos) y guiones medios "-"'
        }
      },
      // Otros
      titleOptions: [
        {
          value: 'one-line',
          label: 'En una sola linea'
        },
        {
          value: 'all',
          label: 'Todo el contenido'
        }
      ],
      descriptionOptions: [
        {
          value: 'one-line',
          label: 'En una sola linea'
        },
        {
          value: 'all',
          label: 'Todo el contenido'
        },
        {
          value: 'hide',
          label: 'Ocultar'
        }
      ],
      priceOptions: [
        {
          value: 'first',
          label: 'Primer precio'
        },
        {
          value: 'all',
          label: 'Todo los precios'
        }
      ],
      allergensOptions: [
        {
          value: 'hide',
          label: 'Ocultar'
        },
        {
          value: 'show',
          label: 'Mostrar'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Get the correct icon to put after url field
     */
    appendIconUrlField() {
      return !this.$v.formFields.url.urlAvailable ? 'mdi-close' : 'mdi-check'
    },
    /**
     * Get the correct color icon to put after url field
     */
    appendIconColorUrlField() {
      return !this.$v.formFields.url.urlAvailable ? 'red' : 'green'
    },
    /**
     * Devuelve las configuraciones de los color pickers
     */
    colorPickerVOptions() {
      return {
        'hide-inputs': true,
        'hide-canvas': true
      }
    },
    /**
     * Build the place URL
     */
    placeUrlAppMenu() {
      return this.formFields.url ? `${URL_APP_MENU}${this.formFields.url}` : URL_APP_MENU
    },
    /**
     * Opciones de la previsualización
     *
     * @return {Object}
     */
    previewOptions() {
      return {
        fontFamily: this.formFields.fontFamily,
        fontColor: this.formFields.fontColor,
        themeColor: this.formFields.themeColor,
        backgroundColor: this.formFields.backgroundColor
      }
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs', 'updatePlace']),
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})

      this.formFields = {
        color: placeConfig.color || THEME_SECONDARY_COLOR,
        cover: placeConfig.cover || null,
        url: this.placeData.url || null
      }

      // AddOn "premimMenu"
      if (this.hasPremiumMenuAddon) {
        const premiumMenuConfig = get(this.placeAddonsSetupByUser, ADDONS.premiumMenu, {})

        // Style options
        this.$set(this.formFields, 'backgroundColor', premiumMenuConfig.backgroundColor || '#FFF')
        this.$set(this.formFields, 'fontColor', premiumMenuConfig.fontColor || '#555')
        this.$set(this.formFields, 'fontFamily', premiumMenuConfig.fontFamily || 'Work Sans')
        this.$set(this.formFields, 'themeColor', premiumMenuConfig.themeColor || placeConfig.color)
        // List options
        this.$set(
          this.formFields,
          'allergensOptionsInLists',
          premiumMenuConfig.allergensOptionsInLists || 'hide'
        )
        this.$set(
          this.formFields,
          'descriptionOptionsInLists',
          premiumMenuConfig.descriptionOptionsInLists || 'one-line'
        )
        this.$set(
          this.formFields,
          'priceOptionsInLists',
          premiumMenuConfig.priceOptionsInLists || 'first'
        )
        this.$set(
          this.formFields,
          'titleOptionsInLists',
          premiumMenuConfig.titleOptionsInLists || 'one-line'
        )
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      const addOns = [
        {
          id: ADDONS.place,
          configFields: {
            color: this.formFields.color,
            cover: this.formFields.cover
          }
        }
      ]

      // Datos a almacenar en "premiumMenu"
      if (this.hasPremiumMenuAddon) {
        addOns.push({
          id: ADDONS.premiumMenu,
          configFields: {
            // Style
            backgroundColor: this.formFields.backgroundColor,
            fontColor: this.formFields.fontColor,
            fontFamily: this.formFields.fontFamily,
            themeColor: this.formFields.themeColor,
            // List
            allergensOptionsInLists: this.formFields.allergensOptionsInLists,
            descriptionOptionsInLists: this.formFields.descriptionOptionsInLists,
            priceOptionsInLists: this.formFields.priceOptionsInLists,
            titleOptionsInLists: this.formFields.titleOptionsInLists
          }
        })
      }

      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns
      })

      // Actualizamos establecimiento
      await this.updatePlace({
        id: this.placeData.id,
        url: this.formFields.url
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations() {
    const that = this
    const rules = {
      formFields: {
        color: {},
        cover: {},
        url: {
          required,
          minLength: minLength(4),
          urlAvailable: async (value) => {
            let available = false

            if (value && value.length > 3) {
              const { ok } = await isUrlAvailable(value, that.placeData.id)
              available = ok
            }
            return available
          },
          urlFormat: (value) => {
            return isOnlyNumbersLettersAndDash(value)
          }
        }
      }
    }

    // AddOn "premimMenu"
    if (this.hasPremiumMenuAddon) {
      // Style
      rules.formFields.backgroundColor = {}
      rules.formFields.fontColor = {}
      rules.formFields.fontFamily = {}
      rules.formFields.themeColor = {}
      // List
      rules.formFields.allergensOptionsInLists = {}
      rules.formFields.descriptionOptionsInLists = {}
      rules.formFields.priceOptionsInLists = {}
      rules.formFields.titleOptionsInLists = {}
    }

    return rules
  }
}
