export default {
  name: 'PremiumMenuPreview',
  props: {
    backgroundColor: {
      type: String,
      default: '#FFF'
    },
    fontColor: {
      type: String,
      default: '#000'
    },
    fontFamily: {
      type: String,
      default: 'sans-serif'
    },
    themeColor: {
      type: String,
      default: '#666'
    },
    title: {
      type: String,
      default: 'Texto de ejemplo'
    }
  },
  computed: {
    /**
     * Establece los estilos que el "toolbar"
     * poseerá
     *
     * @return {String}
     */
    stylesToolbar() {
      return `background-color:${this.themeColor} !important`
    },
    /**
     * Establece los estilos que el "title"
     * poseerá
     *
     * @return {String}
     */
    stylesTitle() {
      return `font-family:'${this.fontFamily}' !important; color:${this.fontColor} !important`
    },
    /**
     * Establece los estilos que el "content"
     * poseerá
     *
     * @return {String}
     */
    stylesContent() {
      return `background-color:${this.backgroundColor} !important`
    }
  }
}
